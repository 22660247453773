import React, { useEffect, useState } from "react";
import Url from "../../global";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import axios from "axios";
import { useForm } from "react-hook-form";
import "../../GlobalvalidasiErorr.css";
import { useSelector } from "react-redux";

const AddSuratMasuk = () => {
  const [msg, setMsg] = useState("");
  const [judulSurat, setJudulSurat] = useState("");
  const [noSurat, setNoSurat] = useState("");
  const [tujuan, setTujuan] = useState("");
  const [tahunAjaran, setTahunAjaran] = useState("");
  const [tglSurat, setTglSurat] = useState("");
  const [tglSuratFix, setTglSuratFix] = useState("");
  const [semester, setSemester] = useState("");
  const [perihal, setPerihal] = useState("");
  const [lampiran, setLampiran] = useState("");
  const [statusOptions, setStatusOptions] = useState(["Pengajuan Surat Staff TU"]);
  const [selectedStatus, setSelectedStatus] = useState("Pengajuan Surat Staff TU");

  const [tembusan, setTembusan] = useState("")
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [setError] = useState(null);
  const { user } = useSelector((state) => state.auth);


  const {
    register,
    reset,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm();


  useEffect(() => {
    if (user && user.strRole === "Kepala Sekolah") {
      if (selectedStatus === "Pengajuan Surat Staff TU") {
        setTujuan("Sekretaris Eksekutif");
        setStatusOptions([
          "Pengajuan Surat Staff TU",
          "Pengajuan Surat Kepala Sekolah",
        ]);
      } else if (selectedStatus === "Pengajuan Surat Kepala Sekolah") {
        setStatusOptions([
          "Pengajuan Surat Staff TU",
          "Pengajuan Surat Kepala Sekolah",
        ]);
        setTujuan("--Pilih--"); // Reset tujuan agar pengguna dapat memilih lagi
      }
    }
  }, [user, selectedStatus]);

  useEffect(() => {
    // Mendapatkan tanggal dan waktu saat ini
    const currentDate = new Date();
    
    // Menambahkan offset untuk zona waktu WIB (UTC+7)
    const timezoneOffset = 7 * 60 * 60 * 1000; // 7 jam dalam milidetik
    const wibDate = new Date(currentDate.getTime() + timezoneOffset);
    
    // Format sesuai untuk input datetime-local
    const formattedDate = wibDate.toISOString().slice(0, 16);
    setTglSurat(formattedDate); // Atur tglSurat menjadi tanggal dan waktu WIB
  }, []);
  

  const tujuanOptions = () => {
    // Check if user is defined and has the strRole property
    if (user && user.strRole) {
      if (user.strRole === "Kepala Sekolah") {
        if (selectedStatus === "Pengajuan Surat Staff TU") {
          return (
            <>
              <option value="Ketua Yayasan">Ketua Yayasan</option>
              <option value="Sekretaris Eksekutif">Sekretaris Eksekutif</option>
            </>
          );
        } else if (selectedStatus === "Pengajuan Surat Kepala Sekolah") {
          return (
            <>
              <option value="Ketua Yayasan">Ketua Yayasan</option>
              <option value="Sekretaris Eksekutif">Sekretaris Eksekutif</option>
              <option value="Kabid Kepegawaian">Kabid Kepegawaian</option>
              <option value="Kabid Pendidikan dan Penjamin Mutu">
                Kabid Pendidikan dan Penjamin Mutu
              </option>
              <option value="Kabid Sarpras, IT dan Humas">
                Kabid Sarpras, IT dan Humas
              </option>
            </>
          );
        }
      } else if (user.strRole === "Staff TU") {
        return (
          <>
            <option value="Ketua Yayasan">Ketua Yayasan</option>
            <option value="Sekretaris Eksekutif">Sekretaris Eksekutif</option>
          </>
        );
      } else {
        // Options for other roles like Admin, etc.
        return (
          <>
            <option value="Ketua Yayasan">Ketua Yayasan</option>
            <option value="Sekretaris Eksekutif">Sekretaris Eksekutif</option>
            <option value="Kabid Kepegawaian">Kabid Kepegawaian</option>
            <option value="Kabid Pendidikan dan Penjamin Mutu">
              Kabid Pendidikan dan Penjamin Mutu
            </option>
            <option value="Kabid Sarpras, IT dan Humas">
              Kabid Sarpras, IT dan Humas
            </option>
          </>
        );
      }
    } else {
      // Return a default option or handle the case when user is not defined
      return <option value="">--Pilih Tujuan--</option>;
    }
  };





  const PdfViewer = ({ fileUrl }) => {
    return (
      <div>
        {fileUrl ? (
          <embed
            src={fileUrl}
            type="application/pdf"
            width="70%"
            height="600px"
          />
        ) : (
          <p>No PDF file uploaded</p>
        )}
      </div>
    );
  };

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);
      setFileUrl(URL.createObjectURL(selectedFile));
    } else {
      setFile(null);
      setError("File harus berupa PDF");
    }
  };

  const saveSuratMasuk = async () => {
    if (file) {
      try {
        await axios.post(
          `${Url}/suratMasuk`,
          {
            strJudulSurat: judulSurat,
            strNoSurat: noSurat,
            dateTglSurat: tglSurat,
            dateTglSuratFix: tglSuratFix,
            strSemester: semester,
            strTahunAjaran: tahunAjaran,
            strPerihal: perihal,
            strLampiran: lampiran,
            strStatus: selectedStatus,
            strTujuan: tujuan,
            strTembusan: tembusan,
            pdfFile: file,
          },
          {
            headers: {
              "Content-type": "multipart/form-data",
            },
          }
        );
        console.log("tes");
        navigate("/surat-masuk");
        reset();
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    }
  };

  return (
    <div className="container ">
      <div className="crud shadow-lg p-3 mb-5 mt-5 bg-bSuratody rounded">
        <div
          className="col-sm-12 mt-5 mb-4  text-center"
          style={{ color: "blue" }}
        >
          <h2>
            <b>Tambah Surat Masuk</b>
          </h2>
        </div>
        <div className="card is-shadowless">
          <div className="card-content">
            <div className="content">
              <p className="has-text-centered">{msg}</p>
              <form className="box" onSubmit={handleSubmit(saveSuratMasuk)}>
                <div className="field">
                  <label className="label">Judul Surat</label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        className="dropdown"
                        value={judulSurat}
                        onChange={(e) => setJudulSurat(e.target.value)}
                        required
                      >
                        <option>------Pilih------</option>
                        <option value="Surat Pernyataan">Surat Pernyataan</option>
                        <option value="Surat Pemohonan">Surat Pemohonan</option>
                        <option value="Surat Pemesanan Buku">Surat Pemesanan Buku</option>
                        <option value="Surat Hasil Microteaching">Surat Hasil Microteaching</option>
                        <option value="Surat Cuti">Surat Cuti</option>
                        <option value="Surat Pemberitahuan">Surat Pemberitahuan</option>
                        <option value="Surat Undangan">Surat Undangan</option>
                        <option value="Surat Laporan">Surat Laporan</option>
                        <option value="Surat Teguran">Surat Teguran</option>
                        <option value="Surat Biodata Guru dan Karyawan">Surat Biodata Guru dan Karyawan</option>
                        <option value="Surat Jadwal Piket Satpam">Surat Jadwal Piket Satpam</option>
                        <option value="Surat Pinjaman Koperasi Gukar">Surat Pinjaman Koperasi Gukar</option>
                        <option value="Surat Pengunduran Diri dari Yayasan">Surat Pengunduran Diri dari Yayasan</option>
                        <option value="Surat Pengunduran Diri dari Koperasi">Surat Pengunduran Diri dari Koperasi</option>
                        <option value="Surat Rekomendasi Kontrak Guru">Surat Rekomendasi Kontrak Guru</option>
                        <option value="Surat Pengajuan">Surat Pengajuan</option>
                        <option value="Surat Keputusan">Surat Keputusan</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Nomor Surat</label>
                  <div className="control">
                    <input
                      type="text"
                      className={`input ${errors.noSurat && "invalid"}`}
                      {...register("noSurat", {
                        required: {
                          value: true,
                          message: "No Surat Wajib Di isi!",
                        },
                        minLength: {
                          value: 5,
                          message: "minimal 5 karakter",
                        },
                      })}
                      onKeyUp={() => {
                        trigger("noSurat");
                      }}
                      placeholder="Nomor Surat"
                      value={noSurat}
                      onChange={(e) => setNoSurat(e.target.value)}
                    />
                    {errors.noSurat && (
                      <small className="allValidasiError">
                        {errors.noSurat.message}
                      </small>
                    )}
                  </div>
                </div>
                
                <div className="field">
                  <label className="label">Tanggal Kirim Surat</label>
                  <div className="control">
                    <Form.Group controlId="duedatetime" className="dtp">
                      <Form.Control
                        type="datetime-local"
                        name="duedatetime"
                        placeholder="Tanggal Kirim Surat"
                        value={tglSurat}
                        onChange={(e) => setTglSurat(e.target.value)}
                        disabled
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Tanggal Surat</label>
                  <div className="control">
                    <Form.Group controlId="duedate" className="dtp">
                      <Form.Control
                        type="date"
                        name="duedate"
                        placeholder="Tanggal Surat"
                        value={tglSuratFix}
                        onChange={(e) => setTglSuratFix(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Semester</label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        className="dropdown"
                        value={semester}
                        onChange={(e) => setSemester(e.target.value)}
                        required
                      // disabled="true"
                      >
                        <option>--- Pilih ---</option>
                        <option>Semester 1</option>
                        <option>Semester 2</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Tahun</label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        className="dropdown"
                        value={tahunAjaran}
                        onChange={(e) => setTahunAjaran(e.target.value)}
                        required
                      // disabled="true"
                      >
                        <option>--- Pilih ---</option>
                        <option>2024</option>
                        <option>2025</option>
                        <option>2026</option>
                        <option>2027</option>
                        <option>2028</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Perihal</label>
                  <div className="control">
                    <input
                      type="text"
                      className={`input ${errors.perihal && "invalid"}`}
                      {...register("perihal", {
                        required: {
                          value: true,
                          message: "Perihal Wajib Di isi!",
                        },
                        minLength: {
                          value: 5,
                          message: "minimal 5 karakter",
                        },
                      })}
                      onKeyUp={() => {
                        trigger("perihal");
                      }}
                      placeholder="Perihal"
                      value={perihal}
                      onChange={(e) => setPerihal(e.target.value)}
                    />
                    {errors.perihal && (
                      <small className="allValidasiError">
                        {errors.perihal.message}
                      </small>
                    )}
                  </div>
                </div>

                <div className="field">
                  <label className="label">Lampiran</label>
                  <div className="control">
                    <input
                      type="number"
                      className={`input ${errors.lampiran && "invalid"}`}
                      {...register("lampiran", {
                        required: {
                          value: true,
                          message: "Lampiran Wajib di Isi dengan Angka!",
                        },
                        // minLength: {
                        //   value: 5,
                        //   message: "minimal 5 karakter",
                        // },
                      })}
                      onKeyUp={() => {
                        trigger("lampiran");
                      }}
                      placeholder="Lampiran Wajib di Isi"
                      value={lampiran}
                      onChange={(e) => setLampiran(e.target.value)}
                    />
                    {errors.lampiran && (
                      <small className="allValidasiError">
                        {errors.lampiran.message}
                      </small>
                    )}
                  </div>
                </div>

                <div className="field">
                  <label className="label">Status Surat Masuk</label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        className="dropdown"
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.target.value)}
                        required
                      >
                        {statusOptions.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                {user && user.strRole === "Staff TU" && (
                  <div className="field">
                    <label className="label">Tujuan</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          className="dropdown"
                          value={tujuan}
                          onChange={(e) => setTujuan(e.target.value)}
                          required
                        >
                          <option>--Pilih--</option>
                          <option>Ketua Yayasan</option>
                          <option>Sekretaris Eksekutif</option>
                        </select>
                      </div>
                    </div>
                  </div>
                )}

                {user && user.strRole !== "Staff TU" && (
                  <div className="field">
                    <label className="label">Tujuan</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          className="dropdown"
                          value={tujuan}
                          onChange={(e) => setTujuan(e.target.value)}
                          required
                        >
                          <option>--Pilih--</option>
                          {/* <option>Ketua Yayasan</option>
                          <option>Sekretaris Eksekutif</option>
                          <option>Kabid Kepegawaian</option>
                          <option>Kabid Pendidikan dan Penjamin Mutu</option>
                          <option>Kabid Sarpras, IT dan Humas</option> */}
                         {tujuanOptions()}
                        </select>
                      </div>
                    </div>
                  </div>
                )}

                <div className="field">
                  <label className="label">Tembusan</label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        className="dropdown"
                        value={tembusan}
                        onChange={(e) => setTembusan(e.target.value)}
                        required
                      >
                        <option>--Pilih--</option>
                        <option>Kepala Sekolah</option>
                        <option>Supervisor Qiraati</option>
                        <option>Pimpinan Pondok</option>
                        <option>Kabid Kepegawaian</option>
                        <option>Kabid Pendidikan dan Penjamin Mutu</option>
                        <option>Kabid Sarpras, IT dan Humas</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Surat Masuk Upload</label>
                  <div className="control">
                    <div className="file">
                      <label className="file-label">
                        <input
                          type="file"
                          accept="application/pdf"
                          onChange={handleChange}
                        />
                        {/* <button type="submit">Upload</button> */}
                      </label>
                    </div>
                    <br />
                    <PdfViewer fileUrl={fileUrl} />
                  </div>
                </div>

                <br />
                <div className="field">
                  <div className="control">
                    <button type="submit" className="button is-success">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSuratMasuk;
